import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/pentech.hu/documentation-of-integrations/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Buy Now, Pay Later`}</h1>
    <p>{`Buy now, pay later (BNPL) is a type of short-term financing that allows consumers to make purchases and pay for them at a future date.`}</p>
    <PageDescription mdxType="PageDescription">
  PastPay’s BNPL service provides increased liquidity in B2B transactions for
  both creditors and debtors.
    </PageDescription>
    <h2>{`How does it work?`}</h2>
    <ol {...{
      "start": 0
    }}>
      <li parentName="ol">{`The marketplace or webshop installs the PastPay plugin (or implements the API directly)`}</li>
      <li parentName="ol">{`When a debtor (a.k.a. buyer) places an order`}</li>
      <li parentName="ol">{`PastPay (Péntech) processes the invoice and pays the creditor`}</li>
      <li parentName="ol">{`Later, the debtor pays to PastPay`}</li>
    </ol>
    <h2>{`Dictionary`}</h2>
    <ul>
      <li parentName="ul">{`creditor ➜ supplier ⁄ seller. `}<em parentName="li">{`Technically the webshop operator.`}</em></li>
      <li parentName="ul">{`debtor ➜ buyer ⁄ webshop user.`}</li>
      <li parentName="ul">{`auto KO ➜ part of our internal risk analysis process.`}</li>
    </ul>
    <h2>{`⚠️ Notes`}</h2>
    <ul>
      <li parentName="ul">{`The API is capable to handle single webshops and marketplaces `}<em parentName="li">{`(where the debtor can buy from multiple creditors on one platform)`}</em></li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`GET /debtor/{debtorTaxNumber}/limit`}</inlineCode>{` endpoint contains the addresses for the official headquarter and other official locations. Currently we only accept fundings `}<strong parentName="li">{`shipped`}</strong>{` to these addresses.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      